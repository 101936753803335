import { isValidPhoneNumber } from 'libphonenumber-js'
import type { AnyFn } from '~/ts/types/common'

type ValidatorReturn = boolean | string

type ValidationRules = {
    ruleChain: (...lazyRules: (() => ValidatorReturn)[]) => ValidatorReturn
    required: (value: any) => ValidatorReturn
    string: (value: any) => ValidatorReturn
    number: (value: any) => ValidatorReturn
    email: (value: any) => ValidatorReturn
    phone: (value: any) => ValidatorReturn
    maybePhone: (value: any) => ValidatorReturn
    time: (value: any) => ValidatorReturn
    min: (value: any, min: number, { trim }?: { trim: boolean }) => ValidatorReturn
    max: (value: any, max: number, { trim }?: { trim: boolean }) => ValidatorReturn
    between: (value: any, { min, max, trim }: { min: number, max: number, trim?: boolean }) => ValidatorReturn
    confirmed: (value: any, targetValue: any) => ValidatorReturn
    numeric: (value: any) => ValidatorReturn
    is: (value: any, targetValue: any) => ValidatorReturn
    isNot: (value: any, targetValue: any) => ValidatorReturn
    oneOf: (value: any, targetValues: any[]) => ValidatorReturn
    notOneOf: (value: any, targetValues: any[]) => ValidatorReturn
    allOf: (value: any[], targetValues: any[]) => ValidatorReturn
    notAllOf: (value: any[], targetValues: any[]) => ValidatorReturn
    toAll: (value: any[], validator: AnyFn<ValidatorReturn>) => ValidatorReturn
    url: (value: any) => ValidatorReturn
    custom: (value: any, validator: (value: any) => boolean) => ValidatorReturn
}

const ruleChain: ValidationRules['ruleChain'] = (...lazyRules) => {
    for (const index in lazyRules) {
        const validatorReturn = lazyRules[index]()

        if (isString(validatorReturn)) {
            return validatorReturn
        }
    }

    return true
}

const required: ValidationRules['required'] = (value) => {
    return !isEmpty(value) || useLang().t('required')
}

const string: ValidationRules['string'] = (value) => {
    if (isEmpty(value)) {
        return true
    }

    if (typeof value !== 'string') {
        return useLang().t('must-be-a-string')
    }

    return true
}

const number: ValidationRules['number'] = (value) => {
    if (isEmpty(value)) {
        return true
    }

    if (typeof value !== 'number') {
        return useLang().t('must-be-a-number')
    }

    return true
}

const email: ValidationRules['email'] = (value) => {
    if (isEmpty(value)) {
        return true
    }

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return useLang().t('must-be-a-valid-email')
    }

    return true
}

const phone: ValidationRules['phone'] = (value) => {
    if (isEmpty(value)) {
        return true
    }

    if (!isValidPhoneNumber(value)) {
        return useLang().t('must-be-a-valid-phone')
    }

    return true
}

const maybePhone: ValidationRules['maybePhone'] = (value) => {
    if (isEmpty(value)) {
        return true
    }

    const onlyDigits = stringUtil.getDigits(value)

    if (
        (onlyDigits.length < 6)
        || (onlyDigits.length > 14)
        || !/^[0-9-+()\s]*$/.test(value)
    ) {
        return useLang().t('must-be-a-valid-phone')
    }

    return true
}

const time: ValidationRules['time'] = (value) => {
    if (isEmpty(value)) {
        return true
    }

    if (!/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value)) {
        return useLang().t('must-be-a-valid-time')
    }

    return true
}

const min: ValidationRules['min'] = (value, min, { trim } = { trim: false }) => {
    if (isEmpty(value)) {
        return true
    }

    if (isNumber(value)) {
        if (value < min) {
            return useLang().t('minimum-value-is') + ` - ${ min }`
        }
    } else if ((trim ? value.trim() : value).length < min) {
        return useLang().t('minimum-length-is') + ` - ${ min }`
    }

    return true
}

const max: ValidationRules['max'] = (value, max, { trim } = { trim: false }) => {
    if (isEmpty(value)) {
        return true
    }

    if (isNumber(value)) {
        if (value > max) {
            return useLang().t('maximum-value-is') + ` - ${ max }`
        }
    } else if ((trim ? value.trim() : value).length > max) {
        return useLang().t('maximum-length-is') + ` - ${ max }`
    }

    return true
}

const between: ValidationRules['between'] = (value, { min: _min, max: _max, trim = false }) => {
    if (isEmpty(value)) {
        return true
    }

    const minValidatorReturn = min(value, _min, { trim })

    if (minValidatorReturn !== true) {
        return minValidatorReturn
    }

    const maxValidatorReturn = max(value, _max, { trim })

    if (maxValidatorReturn !== true) {
        return maxValidatorReturn
    }

    return true
}

const confirmed: ValidationRules['confirmed'] = (value, targetValue) => {
    if (value !== targetValue) {
        return useLang().t('must-be-confirmed-and-match')
    }

    return true
}

const numeric: ValidationRules['numeric'] = (value) => {
    if (isEmpty(value)) {
        return true
    }

    const valueString = String(value)

    if (!/^\d*$/.test(valueString)) {
        return useLang().t('must-be-numeric')
    }

    return true
}

const is: ValidationRules['is'] = (value, targetValue) => {
    if (isEmpty(value)) {
        return true
    }

    if (value !== targetValue) {
        return useLang().t('unsuitable-value')
    }

    return true
}

const isNot: ValidationRules['isNot'] = (value, targetValue) => {
    if (isEmpty(value)) {
        return true
    }

    if (value === targetValue) {
        return useLang().t('unsuitable-value')
    }

    return true
}

const oneOf: ValidationRules['oneOf'] = (value: any, targetValues: any[]) => {
    if (isEmpty(value)) {
        return true
    }

    if (!targetValues.includes(value)) {
        return useLang().t('unsuitable-value')
    }

    return true
}

const notOneOf: ValidationRules['notOneOf'] = (value: any, targetValues: any[]) => {
    if (isEmpty(value)) {
        return true
    }

    if (targetValues.includes(value)) {
        return useLang().t('unsuitable-value')
    }

    return true
}

const allOf: ValidationRules['allOf'] = (value: any, targetValues: any[]) => {
    if (isEmpty(value)) {
        return true
    }

    for (const property in value) {
        if (!targetValues.includes(value[property])) {
            return useLang().t('unsuitable-value')
        }
    }

    return true
}

const notAllOf: ValidationRules['notAllOf'] = (value: any, targetValues: any[]) => {
    if (isEmpty(value)) {
        return true
    }

    for (const property in value) {
        if (targetValues.includes(value[property])) {
            return useLang().t('unsuitable-value')
        }
    }

    return true
}

const toAll: ValidationRules['toAll'] = (value, validator) => {
    if (isEmpty(value)) {
        return true
    }

    for (const item of value) {
        const validatorReturn = validator(item)

        if (validatorReturn !== true) {
            return validatorReturn
        }
    }

    return true
}

const url: ValidationRules['url'] = (value) => {
    if (isEmpty(value)) {
        return true
    }

    let url

    try {
        url = new URL(value)

        if (![ 'http:', 'https:' ].includes(url.protocol)) {
            return useLang().t('must-be-a-valid-link')
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
        return useLang().t('must-be-a-valid-link')
    }

    return true
}

const custom: ValidationRules['custom'] = (value, validator) => {
    if (!validator(value)) {
        return useLang().t('unsuitable-value')
    }

    return true
}

export default {
    ruleChain,
    required,
    string,
    number,
    email,
    phone,
    maybePhone,
    time,
    min,
    max,
    between,
    confirmed,
    numeric,
    is,
    isNot,
    oneOf,
    notOneOf,
    allOf,
    notAllOf,
    toAll,
    url,
    custom,
}
