<script
    lang="ts"
    setup
>
    import type { FormContext, FormSubmitContext } from '~/ts/types/form'
    import { FormSubmitPreventedStatus } from '~/ts/enums/form'

    type Props = {
        values?: ArrayConstructor | any
        rules?: ObjectConstructor | any
        submitIfDirty?: boolean
    }

    type Emit = {
        (event: 'submit', values: FormContext['values'], context: FormSubmitContext): void
        (event: 'submit-prevented', status: FormSubmitPreventedStatus): void
        (event: 'submit-prevented:not-valid'): void
        (event: 'submit-prevented:not-dirty'): void
        (event: 'field:error', fieldName: string, value: string): void
    }

    const props = withDefaults(defineProps<Props>(), {
        values: undefined,
        rules: undefined,
        submitIfDirty: false,
    })
    const emit = defineEmits<Emit>()

    const formRef = ref<HTMLFormElement>()

    const isDirty = ref<boolean>(false)
    const errors = shallowReactive<Record<string, string>>({})

    let valuesModel = toRefs(props.values)

    const refreshErrors = () => {
        for (const fieldName in errors) {
            delete errors[fieldName]
        }

        for (const fieldName in props.values) {
            errors[fieldName] = ''
        }
    }

    watch(() => props.values, () => {
        valuesModel = toRefs(props.values)

        refreshErrors()
    }, { immediate: true })

    const validateField: FormContext['validateField'] = (fieldName): boolean => {
        const fieldRule = props.rules?.[fieldName]
        const fieldValue = props.values?.[fieldName]

        let valid = true

        // Якщо помилка вже присутня чи була встановлена кастомна помилка (наприклад, після запиту до сервера)
        if (errors[fieldName]) {
            return false
        }

        if (!fieldRule) {
            return valid
        }

        const fieldValidationResult: true | string = fieldRule(fieldValue)

        if (fieldValidationResult !== true) {
            valid = false

            setFieldError(fieldName, fieldValidationResult as string)
        }

        return valid
    }

    const validate: FormContext['validate'] = () => {
        let valid = true

        const values = {}

        for (const fieldName in props.rules) {
            values[fieldName] = props.values?.[fieldName]

            if (!validateField(fieldName)) {
                valid = false

                break
            }
        }

        return { valid, values }
    }

    const setFieldValue: FormContext['setFieldValue'] = (fieldName, value = ''): void => {
        if (!fieldName) {
            return
        }

        valuesModel[fieldName].value = value

        setFieldError(fieldName)
    }

    const setFieldError: FormContext['setFieldError'] = (fieldName, errorText = ''): void => {
        if (!fieldName) {
            return
        }

        errors[fieldName] = errorText

        emit('field:error', fieldName, errorText)

        if (errorText && import.meta.client) {
            scrollToField(fieldName)
        }
    }

    const setDirty: FormContext['setDirty'] = (value = true): void => {
        isDirty.value = value
    }

    const resetForm: FormContext['resetForm'] = (): void => {
        isDirty.value = false

        for (const fieldName in props.rules) {
            setFieldError(fieldName)
        }

        for (const fieldName in props.values) {
            setFieldValue(fieldName)
        }
    }

    const triggerSubmit: FormContext['triggerSubmit'] = (options = {}): void => {
        const {
            ifDirty = false,
        } = options

        const { valid, values } = validate()

        if (!valid) {
            isDirty.value = false

            emit('submit-prevented', FormSubmitPreventedStatus.NotValid)
            emit('submit-prevented:not-valid')

            return
        }

        if (ifDirty && !isDirty.value) {
            emit('submit-prevented', FormSubmitPreventedStatus.NotDirty)
            emit('submit-prevented:not-dirty')

            return
        }

        isDirty.value = false

        emit('submit', values, submitContext)
    }

    const onSubmit = (): void => {
        triggerSubmit({ ifDirty: props.submitIfDirty })
    }

    const scrollToField = (fieldName: string, smooth = false): void => {
        if (!fieldName) {
            return
        }

        setTimeout(() => {
            document.querySelector(`[name=${ fieldName }]`)?.scrollIntoView({
                inline: 'center',
                block: 'center',
                behavior: smooth ? 'smooth' : 'auto',
            })
        }, 0)
    }

    const formContext = computed<FormContext>(() => ({
        isDirty,
        values: props.values,
        errors,
        triggerSubmit,
        validate,
        resetForm,
        validateField,
        setFieldValue,
        setFieldError,
        setDirty,
    }))

    const submitContext: FormSubmitContext = {
        isDirty,
        errors,
        resetForm,
        validateField,
        setFieldValue,
        setFieldError,
    }

    defineExpose({
        isDirty,
        values: props.values,
        errors,
        triggerSubmit,
        validate,
        resetForm,
        validateField,
        setFieldValue,
        setFieldError,
        setDirty,
        scrollToField,
    })
</script>

<template>
    <form
        ref="formRef"
        :class="$style['form']"
        novalidate
        autocomplete="off"
        @input="setDirty()"
        @submit.prevent="onSubmit"
    >
        <slot v-bind="formContext" />
    </form>
</template>

<style
    lang="sass"
    module
    scoped
>
    .form
        appearance: none
        display: flex
        flex-direction: column
        margin: 0
</style>
